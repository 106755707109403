export class AppMeta {
    public minimumSupportedVersion: AppVersion;
    public currentVersion: AppVersionByPlatform;
    public featureFlags: {
        backgroundGeolocation: boolean;
        banking: boolean;
        orderRequests: boolean;
        campaigns: boolean;
    };

    constructor(initialValues?: Partial<AppMeta>) {
        this.minimumSupportedVersion = initialValues?.minimumSupportedVersion ?? DEFAULT_VERSION;
        this.currentVersion = {
            android: initialValues?.currentVersion?.android ?? DEFAULT_VERSION,
            ios: initialValues?.currentVersion?.ios ?? DEFAULT_VERSION,
            web: initialValues?.currentVersion?.web ?? DEFAULT_VERSION,
        };
        this.featureFlags = {
            backgroundGeolocation: initialValues?.featureFlags?.backgroundGeolocation ?? true,
            banking: initialValues?.featureFlags?.banking ?? false,
            orderRequests: initialValues?.featureFlags?.orderRequests ?? false,
            campaigns: initialValues?.featureFlags?.campaigns ?? false,
        };
    }
}

export type AppVersion = {
    major: number;
    minor: number;
    patch: number;
};

export type AppVersionByPlatform = {
    android: AppVersion;
    ios: AppVersion;
    web: AppVersion;
};

const DEFAULT_VERSION: AppVersion = { major: 0, minor: 0, patch: 0 };
