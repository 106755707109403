import dayjs from "dayjs";
import { ReceiptSettings, ReceiptSettingsType, TimeTrackingMode } from "./ReceiptSettings";

export class DeliveryNoteSettings extends ReceiptSettings {
    public type = ReceiptSettingsType.DELIVERY_NOTE;
    public timeTrackingMode: TimeTrackingMode;
    public content: NoteContentSettings;

    constructor(initialValues?: Partial<DeliveryNoteSettings>) {
        super(initialValues);
        this.timeTrackingMode = initialValues?.timeTrackingMode ?? TimeTrackingMode.COMPACT;
        this.prefix = initialValues?.prefix ?? `LI-${dayjs().year()}`;
        this.content = initialValues?.content ?? DEFAULT_NOTE_CONTENT_SETTINGS;
    }
}

export type NoteContentSettings = {
    showMachineCounters: boolean;
    showMachineName: boolean;
    showEmployeeNames: boolean;
    showOrderStartStopTimes: boolean;
    showOnExport: boolean;
};

export const DEFAULT_NOTE_CONTENT_SETTINGS: NoteContentSettings = {
    showMachineCounters: false,
    showMachineName: false,
    showEmployeeNames: false,
    showOrderStartStopTimes: false,
    showOnExport: false,
};
