import Field from "@farmact/model/src/model/Field";
import { query, where } from "firebase/firestore";
import { Firebase } from "@/firebase/index";

export function getQueryForFieldsWithIds(fieldIds: Field["id"][]) {
    return query(
        Firebase.instance().getAllFields({ companyId: undefined, include: "all" }),
        where("id", "in", fieldIds)
    );
}
