import dayjs from "dayjs";
import { CountryCode } from "./CountryCode";
import { ReceiptSettings, ReceiptSettingsType, TimeTrackingMode } from "./ReceiptSettings";

export class BillSettings extends ReceiptSettings {
    type = ReceiptSettingsType.BILL;
    showPositionTotal?: boolean;
    timeTrackingMode: TimeTrackingMode;
    content: BillNoteContentSettings;
    printPaymentInfo: boolean;
    showGiropayCode: boolean;
    enableReverseCharge: boolean;
    reverseChargeOutro: string;

    constructor(initialValues?: Partial<BillSettings>) {
        super(initialValues);
        this.showPositionTotal = initialValues?.showPositionTotal ?? true;
        this.timeTrackingMode = initialValues?.timeTrackingMode ?? TimeTrackingMode.COMPACT;
        this.prefix = initialValues?.prefix ?? `RE-${dayjs().year()}`;
        this.content = initialValues?.content ?? DEFAULT_BILL_NOTE_CONTENT_SETTINGS;
        this.printPaymentInfo = initialValues?.printPaymentInfo ?? true;
        this.showGiropayCode = initialValues?.showGiropayCode ?? false;
        this.enableReverseCharge = initialValues?.enableReverseCharge ?? false;
        this.reverseChargeOutro = initialValues?.reverseChargeOutro ?? "";
    }
}

export const getDefaultVatForCountry = (country: CountryCode) => {
    switch (country) {
        case "DE":
            return 19;
        case "CH":
            return 7.7;
        case "AT":
            return 20;
        case "IT":
            return 22;
        default:
            return 0;
    }
};

export type BillNoteContentSettings = {
    showMachineCounters: boolean;
    showMachineName: boolean;
    showEmployeeNames: boolean;
    showOrderStartStopTimes: boolean;
    showOnExport: boolean;
};

export const DEFAULT_BILL_NOTE_CONTENT_SETTINGS: BillNoteContentSettings = {
    showMachineCounters: false,
    showMachineName: false,
    showEmployeeNames: false,
    showOrderStartStopTimes: false,
    showOnExport: false,
};
