import { Order } from "./Order";
import { OrderBasics } from "./OrderBasics";
import { OrderStatus } from "./OrderStatus";

export class Campaign extends OrderBasics {
    status: OrderStatus;
    orderRoles: { [orderId: Order["id"]]: OrderRole };

    constructor(initialValues?: Partial<Campaign>) {
        super(initialValues);
        this.status = initialValues?.status ?? OrderStatus.DRAFT;
        this.orderRoles = initialValues?.orderRoles ?? {};
    }
}

export enum OrderRole {
    LEADING = "OrderRole_LEADING",
    FOLLOWING = "OrderRole_FOLLOWING",
}

export function getOrderRoleName(role: OrderRole) {
    switch (role) {
        case OrderRole.LEADING:
            return "Leitfahrzeug";
        case OrderRole.FOLLOWING:
            return "Folgefahrzeug";
    }
}
