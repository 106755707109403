import { is, nullable, number, object, string, type } from "superstruct";
import { v4 } from "uuid";
import { Address } from "./Address";
import { Vertex } from "./Vertex";

export class Project {
    id: string;
    name: string;
    customerId: string;
    note: string | null;
    createdAt: string; // ISO datetime
    startDate: string | null; // ISO datetime
    endDate: string | null; // ISO datetime
    latLng: Vertex | null;
    address: Omit<Address, "name">;

    constructor(initialValues?: Partial<Project>) {
        this.id = initialValues?.id ?? v4();
        this.name = initialValues?.name ?? "";
        this.customerId = initialValues?.customerId ?? "";
        this.note = initialValues?.note ?? null;
        this.createdAt = initialValues?.createdAt ?? new Date().toISOString();
        this.startDate = initialValues?.startDate ?? null;
        this.endDate = initialValues?.endDate ?? null;
        this.address = initialValues?.address ?? {
            street: "",
            zip: "",
            city: "",
            countryCode: "DE",
        };
        this.latLng = initialValues?.latLng ?? null;
    }
}

const IsProjectStruct = type({
    id: string(),
    name: string(),
    customerId: string(),
    note: nullable(string()),
    createdAt: string(),
    startDate: nullable(string()),
    endDate: nullable(string()),
    latLng: nullable(
        object({
            lat: number(),
            lng: number(),
        })
    ),
    address: object({
        street: string(),
        zip: string(),
        city: string(),
        countryCode: string(),
    }),
});

export function isProject(candidate: any): candidate is Project {
    return is(candidate, IsProjectStruct);
}
