import Bugsnag from "@bugsnag/js";
import {
    ChangeEmployeeActiveStateRequestData,
    ChangeEmployeePasswordRequestData,
    ChangeEmployeePasswordResponseData,
    ChangeEmployeeRoleRequestData,
    DeleteAccountRequestData,
    KickCustomerRequestData,
    KickEmployeeRequestData,
} from "@farmact/model/src/model/functions/accessManagementFunctionTypes";
import {
    SetupCheckoutSessionRequestData,
    SetupCheckoutSessionResponseData,
    SetupCustomerPortalSessionRequestData,
    SetupCustomerPortalSessionResponseData,
} from "@farmact/model/src/model/functions/billingFunctionTypes";
import {
    AcceptInvitationRequestData,
    AcceptInvitationResponseData,
    AcceptMailInvitationRequestData,
    CancelCustomerInvitationRequestData,
    CancelEmployeeInvitationRequestData,
    InvitationResponseData,
    InviteCustomerRequestData,
    InviteEmployeeRequestData,
    RejectInvitationRequestData,
    RejectInvitationResponseData,
} from "@farmact/model/src/model/functions/invitationFunctionTypes";
import {
    BaseMapStructuresSharingInsertResponse,
    BaseMapStructuresSharingUpdateResponse,
    BaseSharingRequest,
    DeleteFieldMarkersForCustomerRequest,
    DeleteFieldTracksForCustomerRequest,
    GetCurrentlyActiveEmployeeLocationsForCustomerResponse,
    GetCurrentlyActiveOrderGeolocationTrackingsForCustomerResponse,
    GetFieldsByIdsForCustomerRequest,
    GetFieldsByIdsForCustomerResponse,
    GetFieldsDataForCustomerRequest,
    GetMapStructuresDataForCustomerResponse,
    GetSharingTokenPayloadResponse,
    GetTracksByIdsForCustomerRequest,
    GetTracksByIdsForCustomerResponse,
    InsertFieldMarkersForCustomerRequest,
    InsertFieldTracksForCustomerRequest,
    InsertFieldsForCustomerRequest,
    InsertLoadingOrUnloadingPointsForCustomerRequest,
    InsertMarkersForCustomerRequest,
    InsertTracksForCustomerRequest,
    UpdateCustomerPositionForCustomerRequest,
    UpdateFieldsForCustomerRequest,
    UpdateLoadingOrUnloadingPointsForCustomerRequest,
    UpdateMarkerForCustomerRequest,
    UpdateTrackForCustomerRequest,
} from "@farmact/model/src/model/functions/sharingFunctionTypes";
import {
    AnyArchiveOperatingUnitResponse,
    AnyCreateNewOperatingUnitResponse,
    ArchiveOperatingUnitRequest,
    CreateNewOperatingUnitRequest,
    SetAllCustomersMaschinenringSettingRequest,
    ToggleCheckedOrdersRequestData,
    UpdateAppUserEmailForEmployeeRequestData,
} from "@farmact/model/src/model/functions/webAppFunctionTypes";
import { Role } from "@farmact/model/src/model/Role";
import { Functions, httpsCallable } from "firebase/functions";

export class FirebaseFunctions {
    private functions: Functions;

    constructor(parameters: { functions: Functions }) {
        this.functions = parameters.functions;
    }

    // FIXME: TODO: what is going on here
    public async inviteEmployee(
        inviteeMail: string,
        employeeId: string,
        password: string
    ): Promise<InvitationResponseData> {
        return (
            await httpsCallable<InviteEmployeeRequestData, InvitationResponseData>(
                this.functions,
                "accessManagement-inviteUser"
            )({
                userMail: inviteeMail,
                employeeId,
                password,
            })
        ).data;
    }

    public async inviteCustomer(
        inviteeMail: string,
        customerId: string,
        usersCompanyId: string | undefined
    ): Promise<InvitationResponseData> {
        if (!usersCompanyId) {
            Bugsnag.notify("No company id set when inviting customer");
            throw new Error("companyId not set");
        }
        return (
            await httpsCallable<InviteCustomerRequestData, InvitationResponseData>(
                this.functions,
                "accessManagement-inviteCustomer"
            )({
                customerMail: inviteeMail,
                companyId: usersCompanyId,
                customerId,
            })
        ).data;
    }

    public async acceptInvitation(invitationId: string) {
        return await httpsCallable<AcceptInvitationRequestData, AcceptInvitationResponseData>(
            this.functions,
            "accessManagement-acceptInvitation"
        )({
            invitationId,
        });
    }

    public async acceptMailInvitation(mailInvitationId: string, password: string) {
        return await httpsCallable<AcceptMailInvitationRequestData, AcceptInvitationResponseData>(
            this.functions,
            "accessManagement-acceptMailInvitation"
        )({
            mailInvitationId,
            password,
        });
    }

    public rejectInvitation(invitationId: string) {
        return httpsCallable<RejectInvitationRequestData, RejectInvitationResponseData>(
            this.functions,
            "accessManagement-rejectInvitation"
        )({ invitationId });
    }

    public cancelInvitation(invitedEmployeeId: string, usersCompanyId: string | undefined) {
        if (!usersCompanyId) {
            Bugsnag.notify("No company id set when cancelling employee invitation");
            throw new Error("companyId not set");
        }
        return httpsCallable<CancelEmployeeInvitationRequestData>(
            this.functions,
            "accessManagement-cancelInvitation"
        )({
            invitingCompanyId: usersCompanyId,
            invitedEmployeeId,
        });
    }

    public cancelCustomerInvitation(invitedCustomerId: string, usersCompanyId: string | undefined) {
        if (!usersCompanyId) {
            Bugsnag.notify("No company id set when cancelling customer invitation");
            throw new Error("companyId not set");
        }
        return httpsCallable<CancelCustomerInvitationRequestData>(
            this.functions,
            "accessManagement-cancelCustomerInvitation"
        )({
            invitingCompanyId: usersCompanyId,
            invitedCustomerId,
        });
    }

    public kickEmployee(employeeId: string, usersCompanyId: string | undefined) {
        if (!usersCompanyId) {
            Bugsnag.notify("No company id set when kicking employee");
            throw new Error("companyId not set");
        }
        return httpsCallable<KickEmployeeRequestData>(
            this.functions,
            "accessManagement-kickEmployee"
        )({
            companyId: usersCompanyId,
            employeeId,
        });
    }

    public kickCustomer(customerId: string, usersCompanyId: string) {
        return httpsCallable<KickCustomerRequestData>(
            this.functions,
            "accessManagement-kickCustomer"
        )({
            companyId: usersCompanyId,
            customerId,
        });
    }

    public changeEmployeePassword(appUserId: string, companyId: string, newPassword: string) {
        return httpsCallable<ChangeEmployeePasswordRequestData, ChangeEmployeePasswordResponseData>(
            this.functions,
            "accessManagement-changeEmployeePassword"
        )({
            companyId,
            appUserId,
            newPassword,
        });
    }

    public deleteAccount(userId: string) {
        return httpsCallable<DeleteAccountRequestData>(this.functions, "accessManagement-deleteAccount")({ userId });
    }

    public updateEmployeeRole(
        appUserId: string,
        employeeId: string,
        newRole: Role,
        usersCompanyId: string | undefined
    ) {
        if (!usersCompanyId) {
            Bugsnag.notify("No company id set when updating employee role");
            throw new Error("companyId not set");
        }
        return httpsCallable<ChangeEmployeeRoleRequestData>(
            this.functions,
            "accessManagement-changeEmployeeRole"
        )({
            companyId: usersCompanyId,
            employeeId,
            appUserId,
            newRole,
        });
    }

    public setupCheckoutSession(usersCompanyId: string | undefined) {
        if (!usersCompanyId) {
            Bugsnag.notify("No company id set when setting up checkout session");
            throw new Error("companyId not set");
        }
        return httpsCallable<SetupCheckoutSessionRequestData, SetupCheckoutSessionResponseData>(
            this.functions,
            "billing-setupCheckoutSession"
        )({
            companyId: usersCompanyId,
        });
    }

    public setupCustomerPortalSession(usersCompanyId: string | undefined) {
        if (!usersCompanyId) {
            Bugsnag.notify("No company id set when setting up customer portal session");
            throw new Error("companyId not set");
        }
        return httpsCallable<SetupCustomerPortalSessionRequestData, SetupCustomerPortalSessionResponseData>(
            this.functions,
            "billing-setupCustomerPortalSession"
        )({
            companyId: usersCompanyId,
        });
    }

    public setAllCustomersMaschinenringSetting(request: SetAllCustomersMaschinenringSettingRequest) {
        return httpsCallable<SetAllCustomersMaschinenringSettingRequest, void>(
            this.functions,
            "webApp-setAllCustomersMaschinenringSetting"
        )(request);
    }

    public createNewOperatingUnit(request: CreateNewOperatingUnitRequest) {
        return httpsCallable<CreateNewOperatingUnitRequest, AnyCreateNewOperatingUnitResponse>(
            this.functions,
            "webApp-createNewOperatingUnit"
        )(request);
    }

    public archiveOperatingUnit(request: ArchiveOperatingUnitRequest) {
        return httpsCallable<ArchiveOperatingUnitRequest, AnyArchiveOperatingUnitResponse>(
            this.functions,
            "webApp-archiveOperatingUnit"
        )(request);
    }

    public changeEmployeeActiveState(data: ChangeEmployeeActiveStateRequestData) {
        return httpsCallable<ChangeEmployeeActiveStateRequestData>(
            this.functions,
            "accessManagement-changeEmployeeActiveState"
        )(data);
    }

    public toggleCheckedOrders(data: ToggleCheckedOrdersRequestData) {
        return httpsCallable<ToggleCheckedOrdersRequestData>(this.functions, "webApp-toggleCheckedOrders")(data);
    }

    public getCurrentlyActiveOrderGeolocationTrackingsForCustomer(data: BaseSharingRequest) {
        return httpsCallable<BaseSharingRequest, GetCurrentlyActiveOrderGeolocationTrackingsForCustomerResponse>(
            this.functions,
            "sharing-getCurrentlyActiveOrderGeolocationTrackingsForCustomer"
        )(data);
    }

    public getCurrentlyActiveEmployeeLocationsForCustomer(data: BaseSharingRequest) {
        return httpsCallable<BaseSharingRequest, GetCurrentlyActiveEmployeeLocationsForCustomerResponse>(
            this.functions,
            "sharing-getCurrentlyActiveEmployeeLocationsForCustomer"
        )(data);
    }

    public getFieldsDataForCustomer(data: GetFieldsDataForCustomerRequest) {
        return httpsCallable<GetFieldsDataForCustomerRequest, GetMapStructuresDataForCustomerResponse>(
            this.functions,
            "sharing-getFieldsDataForCustomer"
        )(data);
    }

    public getFieldsByIdsForCustomer(data: GetFieldsByIdsForCustomerRequest) {
        return httpsCallable<GetFieldsByIdsForCustomerRequest, GetFieldsByIdsForCustomerResponse>(
            this.functions,
            "sharing-getFieldsByIdsForCustomer"
        )(data);
    }

    public getTracksByIdsForCustomer(data: GetTracksByIdsForCustomerRequest) {
        return httpsCallable<GetTracksByIdsForCustomerRequest, GetTracksByIdsForCustomerResponse>(
            this.functions,
            "sharing-getTracksByIdsForCustomer"
        )(data);
    }

    public updateCustomerPositionForCustomer(data: UpdateCustomerPositionForCustomerRequest) {
        return httpsCallable<UpdateCustomerPositionForCustomerRequest, BaseMapStructuresSharingUpdateResponse>(
            this.functions,
            "sharing-updateCustomerPositionForCustomer"
        )(data);
    }

    public updateFieldsForCustomer(data: UpdateFieldsForCustomerRequest) {
        return httpsCallable<UpdateFieldsForCustomerRequest, BaseMapStructuresSharingUpdateResponse>(
            this.functions,
            "sharing-updateFieldsForCustomer"
        )(data);
    }

    public updateTrackForCustomer(data: UpdateTrackForCustomerRequest) {
        return httpsCallable<UpdateTrackForCustomerRequest, BaseMapStructuresSharingUpdateResponse>(
            this.functions,
            "sharing-updateTrackForCustomer"
        )(data);
    }

    public updateLoadingOrUnloadingPointsForCustomer(data: UpdateLoadingOrUnloadingPointsForCustomerRequest) {
        return httpsCallable<UpdateLoadingOrUnloadingPointsForCustomerRequest, BaseMapStructuresSharingUpdateResponse>(
            this.functions,
            "sharing-updateLoadingOrUnloadingPointsForCustomer"
        )(data);
    }

    public updateMarkerForCustomer(data: UpdateMarkerForCustomerRequest) {
        return httpsCallable<UpdateMarkerForCustomerRequest, BaseMapStructuresSharingUpdateResponse>(
            this.functions,
            "sharing-updateMarkerForCustomer"
        )(data);
    }

    public insertFieldsForCustomer(data: InsertFieldsForCustomerRequest) {
        return httpsCallable<InsertFieldsForCustomerRequest, BaseMapStructuresSharingInsertResponse>(
            this.functions,
            "sharing-insertFieldsForCustomer"
        )(data);
    }

    public insertTracksForCustomer(data: InsertTracksForCustomerRequest) {
        return httpsCallable<InsertTracksForCustomerRequest, BaseMapStructuresSharingInsertResponse>(
            this.functions,
            "sharing-insertTracksForCustomer"
        )(data);
    }

    public insertCustomerMarkersForCustomer(data: InsertMarkersForCustomerRequest) {
        return httpsCallable<InsertMarkersForCustomerRequest, BaseMapStructuresSharingInsertResponse>(
            this.functions,
            "sharing-insertCustomerMarkersForCustomer"
        )(data);
    }

    public insertFieldMarkersForCustomer(data: InsertFieldMarkersForCustomerRequest) {
        return httpsCallable<InsertFieldMarkersForCustomerRequest, BaseMapStructuresSharingInsertResponse>(
            this.functions,
            "sharing-insertFieldMarkersForCustomer"
        )(data);
    }

    public insertFieldTracksForCustomer(data: InsertFieldTracksForCustomerRequest) {
        return httpsCallable<InsertFieldTracksForCustomerRequest, BaseMapStructuresSharingInsertResponse>(
            this.functions,
            "sharing-insertFieldTracksForCustomer"
        )(data);
    }

    public insertLoadingOrUnloadingPointsForCustomer(data: InsertLoadingOrUnloadingPointsForCustomerRequest) {
        return httpsCallable<InsertLoadingOrUnloadingPointsForCustomerRequest, BaseMapStructuresSharingInsertResponse>(
            this.functions,
            "sharing-insertLoadingOrUnloadingPointsForCustomer"
        )(data);
    }

    public deleteFieldTracksForCustomer(data: DeleteFieldTracksForCustomerRequest) {
        return httpsCallable<DeleteFieldTracksForCustomerRequest, BaseMapStructuresSharingUpdateResponse>(
            this.functions,
            "sharing-deleteFieldTracksForCustomer"
        )(data);
    }

    public deleteFieldMarkersForCustomer(data: DeleteFieldMarkersForCustomerRequest) {
        return httpsCallable<DeleteFieldMarkersForCustomerRequest, BaseMapStructuresSharingUpdateResponse>(
            this.functions,
            "sharing-deleteFieldMarkersForCustomer"
        )(data);
    }

    public getSharingTokenPayload(data: BaseSharingRequest) {
        return httpsCallable<BaseSharingRequest, GetSharingTokenPayloadResponse>(
            this.functions,
            "sharing-getSharingTokenPayload"
        )(data);
    }

    public updateUserEmail(data: { appUserId: string; newEmail: string }) {
        return httpsCallable<{ appUserId: string; newEmail: string }>(this.functions, "webApp-updateUserEmail")(data);
    }

    public updateAppUserEmailForEmployee(data: UpdateAppUserEmailForEmployeeRequestData) {
        return httpsCallable<UpdateAppUserEmailForEmployeeRequestData>(
            this.functions,
            "webApp-updateAppUserEmailForEmployee"
        )(data);
    }
}
