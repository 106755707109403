import { Describe, boolean, enums, is, nullable, number, object, string, type } from "superstruct";
import { v4 } from "uuid";

export enum MarkerType {
    ORDER = "order",
    FIELD = "field",
    CUSTOMER = "customer",
    OTHER = "other",
    TRACKING = "tracking",
    DISABLED = "disabled",
    LABEL_ANCHOR = "label-anchor",
    LOADING_POINT = "loading-point",
    UNLOADING_POINT = "unloading-point",
    LOADING_AND_UNLOADING_POINT = "loading-and-unloading-point",
}

export enum CoordinatesRepresentationType {
    DECIMAL = "CoordinatesRepresentationType_DECIMAL",
    DMS = "CoordinatesRepresentationType_DMS",
}

export class Marker {
    id: string;
    archived: boolean;
    customerId: string | null;
    archivedDate: string | null;
    /**
     * Set when this Marker is attached to a field
     */
    fieldId: string | null;
    preferredRepresentation: CoordinatesRepresentationType;
    lat: number;
    lng: number;
    label: string;
    type: MarkerType;

    constructor(initialData?: Partial<Marker>) {
        this.id = initialData?.id ?? v4();
        this.archivedDate = initialData?.archivedDate ?? null;
        this.archived = initialData?.archived ?? false;
        this.customerId = initialData?.customerId ?? null;
        this.fieldId = initialData?.fieldId ?? null;
        this.lat = initialData?.lat ?? 0;
        this.lng = initialData?.lng ?? 0;
        this.label = initialData?.label ?? "";
        this.type = initialData?.type ?? (initialData?.fieldId ? MarkerType.FIELD : MarkerType.CUSTOMER);
        this.preferredRepresentation = initialData?.preferredRepresentation ?? CoordinatesRepresentationType.DECIMAL;
    }
}

export const MarkerStruct = object({
    id: string(),
    archived: boolean(),
    archivedDate: nullable(string()),
    customerId: nullable(string()),
    fieldId: nullable(string()),
    lat: number(),
    lng: number(),
    label: string(),
    type: enums(Object.values(MarkerType)),
    preferredRepresentation: enums(Object.values(CoordinatesRepresentationType)),
}) satisfies Describe<Marker>;

export const IsMarkerStruct = type({
    lat: number(),
    lng: number(),
    type: string(),
});

export function isMarker(candidate: unknown): candidate is Marker {
    return is(candidate, IsMarkerStruct);
}
