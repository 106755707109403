import { Track } from "@farmact/model/src/model/Track";
import { query, where } from "firebase/firestore";
import { Firebase } from "@/firebase/index";

export function getQueryForTracksWithIds(trackIds: Track["id"][]) {
    return query(
        Firebase.instance().getAllTracks({ companyId: undefined, include: "all" }),
        where("id", "in", trackIds)
    );
}
